import useIsMobile from '@lyra/core/hooks/useIsMobile'
import React, { ReactElement, useCallback, useRef, useState } from 'react'
import {
  Bar,
  ComposedChart,
  DotProps,
  LabelProps,
  Line,
  LineChart as RechartsLineChart,
  LineProps,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { AxisDomain as RechartsAxisDomain, Margin } from 'recharts/types/util/types'
import { Stack, StackProps, useTheme } from 'tamagui'

import { BarChartDataKey } from '../BarChart'

const DEFAULT_LINE_STROKE_WIDTH = 2
const DEFAULT_REFERENCE_LINE_STROKE_WIDTH = 1

const MOBILE_CURSOR_DURATION_MS = 2000

export type ReferenceLineProps = {
  id: string | number
  x?: number
  y?: number
  label: string
  isFront?: boolean
  strokeDasharray?: string
  color?: string
  textColor?: string
  position?: LabelProps['position']
}

export type LineChartDataKey<LineData> = {
  key: keyof LineData
  color: string
  type?: LineProps['type']
  strokeDasharray?: string
  activeDot?:
    | ReactElement<SVGElement>
    | ((props: any) => ReactElement<SVGElement>)
    | DotProps
    | boolean
}

export type LineTooltipProps = TooltipProps<ValueType, NameType>

export type LineDataPoint = {
  [key: string]: any
}

type Props<T extends LineDataPoint> = {
  height: number | string
  // Array of objects
  data: T[]
  // Descriptor for each line
  dataKeys: LineChartDataKey<T>[]
  // Descriptor for bars (optional)
  barDataKeys?: BarChartDataKey[]
  // x-axis key, e.g. 'x' or 'timestamp'
  xAxisDataKey: string
  // Optional props
  tooltipProps?: LineTooltipProps
  xAxisProps?: XAxisProps
  yAxisProps?: YAxisProps
  referenceLinesProps?: ReferenceLineProps[]
  onHover?: (payload: T) => void
  onLeave?: () => void
  chartMargin?: Margin
  domain?: RechartsAxisDomain
} & StackProps

const EMTPY_MARGIN: Margin = { top: 0, left: 0, bottom: 0, right: 0 }

const LineChart = <T extends LineDataPoint>({
  data,
  dataKeys,
  barDataKeys,
  onHover,
  onLeave,
  tooltipProps,
  xAxisProps,
  yAxisProps,
  referenceLinesProps,
  xAxisDataKey = 'x',
  chartMargin,
  domain,
  ...stackProps
}: Props<T>): JSX.Element => {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const [showCursor, setShowCursor] = useState(true)

  const timeout = useRef<NodeJS.Timeout>()

  const axisStroke = theme.gray_3?.val.toString()

  const handleMouseMove = useCallback(
    (state: CategoricalChartState) => {
      if (!state || !state.activePayload || state.activePayload.length === 0) {
        return null
      }
      if (onHover && state.activePayload[0].payload) {
        onHover(state.activePayload[0].payload)
      }
    },
    [onHover]
  )

  const handleMouseLeave = useCallback(() => {
    if (onLeave) {
      onLeave()
    }
  }, [onLeave])

  const ChartComponent = barDataKeys ? ComposedChart : RechartsLineChart

  return (
    <Stack {...stackProps}>
      <ResponsiveContainer height="100%" width="100%">
        <ChartComponent
          margin={{ ...EMTPY_MARGIN, ...chartMargin }}
          data={data}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseDown={(state) => {
            handleMouseMove(state)
            if (isMobile) {
              setShowCursor(true)
              clearTimeout(timeout.current)
              timeout.current = setTimeout(() => {
                setShowCursor(false)
                handleMouseLeave()
              }, MOBILE_CURSOR_DURATION_MS)
            }
          }}
        >
          <XAxis
            axisLine={{
              stroke: theme.hairline?.get(),
            }}
            dataKey={xAxisDataKey}
            type="number"
            fontSize={11}
            tick={{ fill: theme.secondary?.get() }}
            fontFamily="GT Standard"
            domain={['dataMin', 'dataMax']}
            interval="preserveEnd"
            minTickGap={12}
            {...xAxisProps}
          />
          <YAxis
            axisLine={{
              stroke: theme.hairline?.get(),
            }}
            orientation="right"
            tick={{ fill: theme.secondary?.get() }}
            fontFamily="GT Standard"
            fontSize={11}
            type="number"
            domain={domain ?? ['dataMin', 'dataMax']}
            {...yAxisProps}
          />
          {onHover || tooltipProps ? (
            <Tooltip cursor={{ stroke: axisStroke }} content={() => null} {...tooltipProps} />
          ) : null}
          {referenceLinesProps
            ? referenceLinesProps.map(
                ({
                  id,
                  label,
                  color = 'hairline',
                  textColor = 'secondaryText',
                  position,
                  ...referenceLineProps
                }) => {
                  const colorVar = color in theme ? theme[color]?.get() : color
                  const textColorVar = textColor in theme ? theme[textColor]?.get() : textColor
                  return (
                    <ReferenceLine
                      key={id.toString()}
                      stroke={colorVar}
                      strokeWidth={DEFAULT_REFERENCE_LINE_STROKE_WIDTH}
                      label={
                        typeof label === 'string' || typeof label === 'number'
                          ? {
                              value: label,
                              fontFamily: 'GT Standard',
                              fontSize: 11,
                              position,
                              fill: textColorVar,
                            }
                          : label
                      }
                      {...referenceLineProps}
                    />
                  )
                }
              )
            : null}
          {dataKeys.map((dataKey) => {
            const colorVar = dataKey.color in theme ? theme[dataKey.color]?.get() : dataKey.color
            return (
              <Line
                key={dataKey.key.toString()}
                dataKey={dataKey.key.toString()}
                animationDuration={200}
                activeDot={
                  dataKey.activeDot
                    ? dataKey.activeDot
                    : (props) => {
                        const { cx, cy } = props
                        if (!showCursor) {
                          return <></>
                        }
                        return (
                          <Rectangle
                            x={cx - 3}
                            y={cy - 3}
                            height={6}
                            width={6}
                            fill={colorVar}
                            color={colorVar}
                          />
                        )
                      }
                }
                type={dataKey.type}
                strokeWidth={DEFAULT_LINE_STROKE_WIDTH}
                stroke={colorVar}
                strokeDasharray={dataKey.strokeDasharray}
                dot={false}
              />
            )
          })}
          {barDataKeys?.map(({ color, ...item }) => {
            if (item.visible) {
              const colorVar = color in theme ? theme[color]?.get() : color
              return (
                <Bar key={item.key} dataKey={item.key} stackId={item.stackId} fill={colorVar} />
              )
            }
          })}
        </ChartComponent>
      </ResponsiveContainer>
    </Stack>
  )
}

export default LineChart
