import React from 'react'
import {
  Bar,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { StackProps, useTheme, XStack } from 'tamagui'

export type BarChartDataItem = {
  [key: string]: any
}

export type BarChartDataKey = {
  key: string
  color: string
  visible?: boolean
  stackId?: string
}

type Props<T extends BarChartDataItem> = {
  height: number | string
  data: T[]
  dataKeys: BarChartDataKey[]
  tooltipProps?: TooltipProps<ValueType, NameType>
  yAxisProps?: YAxisProps
  xAxisProps?: XAxisProps
} & StackProps

export default function BarChart<T extends BarChartDataItem>({
  data,
  dataKeys,
  tooltipProps,
  yAxisProps,
  xAxisProps,
  ...stackProps
}: Props<T>) {
  const theme = useTheme()
  return (
    <XStack {...stackProps}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart
          data={data}
          margin={{
            top: 0,
            right: 6,
            bottom: 0,
            left: 0,
          }}
        >
          <Tooltip {...tooltipProps} cursor={{ fill: theme.gray_3?.get() }} />
          <YAxis
            axisLine={false}
            fontSize={11}
            tick={{ fill: theme.secondary?.get() }}
            fontFamily="GT Standard"
            orientation="right"
            {...yAxisProps}
          />
          <XAxis
            axisLine={false}
            fontSize={11}
            tick={{ fill: theme.secondary?.get() }}
            fontFamily="GT Standard"
            interval="preserveEnd"
            minTickGap={12}
            {...xAxisProps}
          />
          {dataKeys.map(({ color, ...item }) => {
            if (item.visible) {
              const colorVar = color in theme ? theme[color]?.get() : color
              return (
                <Bar key={item.key} dataKey={item.key} stackId={item.stackId} fill={colorVar} />
              )
            }
          })}
        </RechartsBarChart>
      </ResponsiveContainer>
    </XStack>
  )
}
